const React = require('react');
const PropTypes = require('prop-types');
const { RecommendationsCardVertical } = require('@recommendations-fe/card');
const {
  RecommendationsCarouselFreeWithoutContainer: RecommendationsCarouselFree,
} = require('@recommendations-fe/carousel-free');
const {
  RecommendationsCarouselSnappedWithoutContainer: RecommendationsCarouselSnapped,
} = require('@recommendations-fe/carousel-snapped');
const { IconFactory } = require('@recommendations-fe/icons');
const Links = require('../Links');

const createItem = (item, supportsWebp) => {
  const result = {};
  result.title = item.title;
  result.id = item.id;
  result.url = item.permalink;
  result.image = {
    src: supportsWebp ? item.picture.src_webp : item.picture.src,
    src2x: supportsWebp ? item.picture.src2x_webp : item.picture.src2x,
    size: '248px',
  };
  if (item.shipping) {
    result.shipping = {
      freeShipping: item.shipping.free_shipping,
      text: item.shipping.text,
      fullfilment: item.shipping.fullfilment,
      cross_docking: item.shipping.cross_docking,
      local_pick_up: item.shipping.local_pick_up,
      store_pick_up: item.shipping.store_pick_up,
      type: item.shipping.type,
    };
  }
  result.price = {
    currency_id: item.price.currency_id,
    symbol: item.price.currency_symbol,
    fraction: item.price.price.toString(),
  };
  if (item.installments) {
    result.installments = {
      text: item.installments.text,
      freeInstallments: item.installments.free_installments,
    };
  }
  if (item.discount) {
    result.discount = {
      text: item.discount.text,
      original_price: {
        currency_id: item.discount.original_price.currency_id,
        symbol: item.discount.original_price.currency_symbol,
        fraction: item.discount.original_price.original_price,
        decimal_separator: item.discount.original_price.decimal_separator,
      },
    };
  }

  return result;
};

const Carousel = ({
  data,
  isMobile,
  isWebView,
  supportsWebp,
  userId,
}) => {
  const items = [];

  data?.items?.map(item => (
    items.push(createItem(item, supportsWebp))
  ));

  const { title, subtitle } = data;

  const carouselProps = {
    Card: RecommendationsCardVertical,
    IconFactory,
    items,
    limit: 9,
    title,
    track: { experiment: {}, event_data: {} },
  };

  const carouselFreeProps = {
    ...carouselProps,
    paddingHorizontal: 16,
  };

  const carouselSnappedProps = {
    ...carouselProps,
    slidesPerView: 3,
  };

  return (
    <div className="reputation-carousel">
      {isMobile ? (
        <RecommendationsCarouselFree {...carouselFreeProps} />
      ) : (
        <RecommendationsCarouselSnapped {...carouselSnappedProps} />
      )}
      {subtitle && (
        <Links
          phrase={subtitle}
          linkClass={['reputation-carousel__link']}
          trackName="SEE_MORE_CAROUSEL"
          isWebView={isWebView}
          userId={userId}
        />
      )}
    </div>
  );
};

Carousel.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.shape({
      text: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string,
      })),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }),
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool.isRequired,
  supportsWebp: PropTypes.bool,
  userId: PropTypes.number,
};

module.exports = Carousel;
