const React = require('react');
// const PropTypes = require('prop-types');

const SeeMoreFeedbacksLink = ({ onCardClick, subtitle, kindOfRating }) => {
  const onClick = (e) => {
    if (window.meli_ga) {
      window.meli_ga('send', 'event', {
        eventCategory: 'FEEDBACK',
        eventAction: 'SEE_MORE_FEEDBACK',
        eventLabel: 'FEEDBACK',
      });
    }
    e.preventDefault();
    onCardClick(kindOfRating, 1); // tengo al menos un feedback mas para mostrar si este link es visible
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="feedback-offset__link show-link" href="#" onClick={onClick}>{subtitle}</a>
  );
};

SeeMoreFeedbacksLink.propTypes = {

};

module.exports = SeeMoreFeedbacksLink;
