const React = require('react');
const Links = require('../Links');

const Experience = ({ data, isWebView, userId }) => (
  <div className="experience__wrapper">
    { data.thermometer_section && data.thermometer_section.level.data && data.thermometer_section.level.data.length === 2 && data.thermometer_section.level.data.map(item => (item.id === 'experience'
      ? <p className="experience" key={item.id}><Links phrase={item.data} isWebView={isWebView} userId={userId} /></p>
      : null
    ))}
  </div>
);

module.exports = Experience;
