const React = require('react');
const PropTypes = require('prop-types');
const Experience = require('../Experience');
const Links = require('../Links');

const StoreInfo = ({ nickname, headerSection, isWebView, isMobile, isBrand, userId }) => (
  <div className="store-info">
    <div className="store-info-title">
      <h3 id="store-info__name" className="store-info__name">{nickname}</h3>
    </div>
    { headerSection.points
      ? <p className="store-info__subtitle"><Links phrase={headerSection.points} isWebView={isWebView} userId={userId} /></p>
      : (<div>
        { !isMobile && !isBrand
          ? <Experience data={headerSection} isWebView={isWebView} userId={userId} />
          : null}
      </div>)}
  </div>
);

StoreInfo.propTypes = {
  nickname: PropTypes.string.isRequired,
  isWebView: PropTypes.bool.isRequired,
};

module.exports = StoreInfo;
