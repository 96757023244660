/* eslint-disable no-tabs */
/* eslint-disable indent */
const { SCOPE } = require('./constants');

const sitesNewReputation = ['MLA', 'MLM', 'MLB', 'MCO', 'MLC', 'MLU', 'MPE', 'MEC'];

const siteConfigs = {
  MLA: {
    /* https://perfil-test.mercadolibre.com.ar, 'https://dev.mercadolibre.com.ar:8443', 'https://www.mercadolibre.com.ar', */
    url: 'https://www.mercadolibre.com.ar',
  },
  MLB: {
    url: 'https://www.mercadolivre.com.br',
  },
  MCO: {
    url: 'https://www.mercadolibre.com.co',
  },
  MLU: {
    url: 'https://www.mercadolibre.com.uy',
  },
  MPE: {
    url: 'https://www.mercadolibre.com.pe',
  },
  MEC: {
    url: 'https://www.mercadolibre.com.ec',
  },
  MLM: {
    url: 'https://www.mercadolibre.com.mx',
  },
  MLC: {
    url: 'https://www.mercadolibre.cl',
  },
  CBT: {
    url: 'https://global-selling.mercadolibre.com',
  },
  MBO: {
    url: 'https://www.mercadolibre.com.bo',
  },
  MCR: {
    url: 'https://www.mercadolibre.co.cr',
  },
  MGT: {
    url: 'https://www.mercadolibre.com.gt',
  },
  MHN: {
    url: 'https://www.mercadolibre.com.hn',
  },
  MLV: {
    /* https://perfil-test.mercadolibre.com.ve, 'https://dev.mercadolibre.com.ve:8443', 'https://www.mercadolibre.com.ve', */
    url: 'https://www.mercadolibre.com.ve',
  },
  MNI: {
    url: 'https://www.mercadolibre.com.ni',
  },
  MPA: {
    url: 'https://www.mercadolibre.com.pa',
  },
  MPY: {
    url: 'https://www.mercadolibre.com.py',
  },
  MRD: {
    url: 'https://www.mercadolibre.com.do',
  },
  MSV: {
    url: 'https://www.mercadolibre.com.sv',
  },
};

const isSiteNewReputation = siteId => sitesNewReputation.includes(siteId);

const getDeeplink = ({ url }) => {
  if (!url) return null;
  const absolutePath = url.replace(' ', '+');
  const deeplink = absolutePath.includes('meli://') ? absolutePath : `meli://webview/?url=${encodeURIComponent(absolutePath)}`;
  return deeplink;
};

const mobileNavigation = ({ url, userId, replace = false }) => {
  const deeplink = getDeeplink({ url });
  console.log('url converted to deeplink---->', deeplink, userId);
  window.MobileWebKit.navigation.push(deeplink, { replace });
};

const kindOfRating = (rating) => {
  switch (rating.toUpperCase()) {
    case 'BOA':
    case 'BUENA':
    case 'POSITIVA':
      return 'POSITIVE';
    case 'REGULAR':
    case 'NEUTRAL':
      return 'NEUTRAL';
    case 'RUIM':
    case 'MALA':
    case 'NEGATIVA':
      return 'NEGATIVE';
    default:
      return undefined;
  }
};

const getScope = (scope) => (scope === undefined ? SCOPE.TEST : scope);

const isTest = (scope) => [SCOPE.TEST].includes(getScope(scope));

module.exports = {
  getDeeplink,
  getScope,
  isTest,
  isSiteNewReputation,
  kindOfRating,
  mobileNavigation,
  siteConfigs,
};
