/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const Newbie = require('../components/Newbie');
const Red = require('../components/Red');
const Orange = require('../components/Orange');
const Yellow = require('../components/Yellow');
const LightGreen = require('../components/LightGreen');
const Green = require('../components/Green');

class Thermometer extends React.PureComponent {
  render() {
    const { color } = this.props;
    const thermometers = {
      NEWBIE: <Newbie />,
      RED: <Red />,
      ORANGE: <Orange />,
      YELLOW: <Yellow />,
      LIGHT_GREEN: <LightGreen />,
      GREEN: <Green />,
      GREEN_SILVER: <Green />,
      GREEN_GOLD: <Green />,
      GREEN_PLATINUM: <Green />,
    };
    return thermometers[`${color.toUpperCase()}`];
  }
}

Thermometer.propTypes = {
  color: PropTypes.oneOf([
    'newbie', 'red', 'yellow', 'orange', 'light_green', 'green', 'green_silver', 'green_gold', 'green_platinum',
  ]),
};

/**
 * Expose Component
 */
module.exports = Thermometer;
