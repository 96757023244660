/* istanbul ignore file */
const React = require('react');
// const PropTypes = require('prop-types');
const classNames = require('classnames');
const Image = require('nordic/image');
// const Image = require('frontend-image');
const Links = require('../Links');

const BrandInfo = ({ name, brandLogo, isWebView, hiddenThermometer, userId }) => {
  const classes = classNames(!hiddenThermometer ? 'official-store' : 'official-store-experiment');

  return (
    <div className="store-brand-info__wrapper">
      { brandLogo.picture_map && brandLogo.picture_map.big_logo
        && (
          <div className="store-brand-info">
            <Image
              className="brand-logo"
              src={brandLogo.picture_map.big_logo.secure_url}
              alt="brand logo"
              lazyload="off"
              preload
            />
          </div>)}
      <div className={classes}>
        <h3 className="brand" id="brand"><Links phrase={name} isWebView={isWebView} userId={userId} /></h3>
      </div>
    </div>
  );
};

BrandInfo.propTypes = {
};

module.exports = BrandInfo;
