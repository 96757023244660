const React = require('react');

const Newbie = () => (
  <div className="seller-info__thermometer">
    <svg width="292px" height="8px" viewBox="0 0 292 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Mejoras_V10-FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ASSETS-ZEPLIN" transform="translate(-2066.000000, -111.000000)" fill="#DDDDDD">
          <g id="Group-40" transform="translate(2066.000000, 111.974359)">
            <g id="Group-38" transform="translate(0.437489, 0.000000)">
              <polygon id="Fill-5-Copy-47" points="58.9467456 0 114.08925 0 114.08925 6.93491124 58.9467456 6.93491124" />
              <polygon id="Fill-5-Copy-52" points="4.65405492e-13 0 55.1425045 0 55.1425045 6.93491124 4.65405492e-13 6.93491124" />
              <polygon id="Fill-5-Copy-53" points="118.65383 0 173.796334 0 173.796334 6.93491124 118.65383 6.93491124" />
              <polygon id="Fill-5-Copy-54" points="176.991963 0 232.134467 0 232.134467 6.93491124 176.991963 6.93491124" />
              <polygon id="Fill-5-Copy-55" points="236.318878 0 291.461382 0 291.461382 6.93491124 236.318878 6.93491124" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

/**
 * Expose Component
 */
module.exports = Newbie;
