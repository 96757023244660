const React = require('react');
// const PropTypes = require('prop-types');
const classNames = require('classnames');
const Links = require('../Links');
const Thermometer = require('./components/Thermometer');
const MessageLevel = require('./components/MessageLevel');
const DataLevel = require('./components/DataLevel');
const Spacing = require('../Spacing');

const LevelInfo = ({ data, isWebView, isMobile, isBrand, hiddenThermometer, userId }) => {
  const isTOs = data.brand.value;
  const classes = classNames(isTOs ? 'seller-info--brand' : 'seller-info');

  return (
    <div className={classes}>
      { data.thermometer_section.subtitle
        && <div>
          { isMobile && isBrand
            ? <p className="store-info__subtitle"><Links phrase={data.points} isWebView={isWebView} userId={userId} /></p>
            : null}
          <h3 className="seller-info__title--old">{data.thermometer_section.title}</h3>
          <Links phrase={data.thermometer_section.subtitle} linkClass={['seller-info__subtitle']} isWebView={isWebView} userId={userId} />
        </div>}
      { data.thermometer_section.level.data
        && data.thermometer_section.level.data.length === 2
        && data.thermometer_section.level.data.map(item => (item.id === 'sales'
          ? (<div key={item.id}>
            { !(hiddenThermometer && !data.thermometer_section.level.subtitle)
              && <h3 className="seller-info__title">{data.thermometer_section.title}</h3>}
            <p className="seller-info__subtitle-sales" key={item.id}>
              <Links phrase={item.data} isWebView={isWebView} userId={userId} />
            </p>
          </div>)
          : null
        ))}
      { !hiddenThermometer
        && (
        <div className="seller-info__thermometer">
          <Thermometer color={data.thermometer_section.level.current_level} />
        </div>)}
      { data.thermometer_section.level.subtitle
        && <MessageLevel info={data.thermometer_section.level} />}
      { data.thermometer_section.level.data
        && <DataLevel data={data.thermometer_section.level.data} isWebView={isWebView} userId={userId} />}
      <Spacing />
    </div>
  );
};

LevelInfo.propTypes = {

};

module.exports = LevelInfo;
