/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const NeutralOldReputation = require('../components/NeutralOldReputation');
const PositiveOldReputation = require('../components/PositiveOldReputation');
const NegativeOldReputation = require('../components/NegativeOldReputation');
const NegativeNewReputation = require('../components/NegativeNewReputation');
const NeutralNewReputation = require('../components/NeutralNewReputation');
const PositiveNewReputation = require('../components/PositiveNewReputation');

class Icon extends React.PureComponent {
  render() {
    const { kind, reputation } = this.props;
    const icons = {
      'POSITIVE-OLD-REPUTATION': <PositiveOldReputation />,
      'POSITIVE-NEW-REPUTATION': <PositiveNewReputation />,
      'NEGATIVE-OLD-REPUTATION': <NegativeOldReputation />,
      'NEGATIVE-NEW-REPUTATION': <NegativeNewReputation />,
      'NEUTRAL-OLD-REPUTATION': <NeutralOldReputation />,
      'NEUTRAL-NEW-REPUTATION': <NeutralNewReputation />,
    };
    return icons[`${kind.toUpperCase()}-${reputation.toUpperCase()}`];
  }
}

Icon.propTypes = {
  reputation: PropTypes.oneOf(['old-reputation', 'new-reputation']),
  kind: PropTypes.oneOf(['POSITIVE', 'NEGATIVE', 'NEUTRAL']),
};

/**
 * Expose Component
 */
module.exports = Icon;
