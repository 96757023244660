const React = require('react');

const MessageLevel = ({ info }) => (
  <div className="message">
    { info.title
      ? (<div className="leader-status__icon">
        <svg width="18px" height="19px" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
          <g id="V4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="assets" transform="translate(-5182.000000, -248.000000)" stroke="#10B146" strokeWidth="1.5">
              <g id="Group" transform="translate(5182.000000, 248.000000)">
                <g id="group-(1)" transform="translate(1.000000, 1.000000)">
                  <path
                    d="M13.35,11 C13.3498214,9.08827183 12.3297615,7.32185745 10.674067,
                      6.36614806 C9.01837251,5.41043868 6.97858283,5.41062928 5.32306699,
                      6.36664806 C3.66755114,7.32266685 2.64782137,9.08927183 2.648,11.001 C2.64827614,
                      13.9565518 5.04444816,16.3522761 8,16.352 C10.9555518,16.3517239 13.3512761,
                      13.9555518 13.351,11 L13.35,11 Z"
                    id="Shape"
                  />
                  <path
                    d="M10.114,5.897 L15.951,0.093 M6,5.943 L10.884,0.908 M5.366,
                      5.984 L0,1 M7.837,3.796 L4.775,0.87"
                    id="Shape"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <p className="message__title">{info.title}</p>
        <span className="message__leader">{info.subtitle}</span>
      </div>)
      : <p className="message__subtitle">{info.subtitle}</p>}
  </div>
);

module.exports = MessageLevel;
