const React = require('react');

const Location = ({ data, isMobile }) => (
  <div className="location__wrapper">
    {!isMobile && <h3 className="location-title">{data.title}</h3>}
    <p className="location-subtitle">{data.address}</p>
  </div>
);

module.exports = Location;
