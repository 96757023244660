const React = require('react');
const Message = require('@andes/message');
const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const restclient = require('nordic/restclient')({
  timeout: 3000,
  baseURL: '/perfil/api',
});
const GreyBar = require('./components/GreyBar');
const SeeMoreFeedbacksLink = require('./components/SeeMoreFeedbacksLink');
const RatingListItemNewReputation = require('./components/RatingListItemNewReputation');
const RatingListItemOldReputation = require('./components/RatingListItemOldReputation');

const getRating = (currentRating, newRating) => {
  if (newRating === 'none') {
    return currentRating;
  }
  if (currentRating === newRating) {
    return 'all';
  }
  return newRating;
};

const errorMessage = (siteId) => {
  if (siteId === 'MLB') {
    return 'Ops! Ocorreu um erro';
  }
  return '¡Ups! Hubo un error';
};

class FeedbackInformation extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      feedbacks: props.data.feedback_section.feedback.data,
      userIdentifier: props.userIdentifier,
      limit: props.limit,
      offset: props.offset,
      subtitle: props.data.feedback_section.feedback.subtitle ? props.data.feedback_section.feedback.subtitle : null,
      rating: props.rating,
      newReputation: props.newReputation,
      showSpinner: props.showSpinner,
      siteId: props.siteId,
      userRole: props.userRole,
      error: props.error,
    };
  }

  handleClick(newRating, value) {
    const { rating, userIdentifier, limit, offset, userRole, feedbacks } = this.state;
    if (value > 0) {
      const currentRating = getRating(rating, newRating);
      const filterHasChanged = rating !== currentRating;
      this.setState({
        showSpinner: true,
      });
      restclient.get('/feedback/askForFeedback', {
        params: {
          userIdentifier,
          rating: currentRating,
          limit,
          offset: filterHasChanged ? 0 : offset + limit,
          role: userRole,
        },
      })
        .then((response) => {
          this.setState({
            feedbacks: filterHasChanged
              ? response.data.feedbacks
              : feedbacks.concat(response.data.feedbacks),
            subtitle: response.data.subtitle,
            rating: currentRating,
            limit,
            offset: filterHasChanged ? 0 : offset + limit,
            showSpinner: false,
            error: false,
          });
        })
        .catch(() => {
          this.setState({
            showSpinner: false,
            error: true,
          });
        });
    }
  }

  render() {
    const { data } = this.props;
    const { rating, error, siteId, feedbacks, isMobile, showSpinner, newReputation, subtitle } = this.state;
    return (
      <section className="buyers-feedback-section">
        <h3>{data.feedback_section.title}</h3>
        <div className="buyers-feedback__wrapper">
          <span className="total">{data.feedback_section.feedback.all.value}</span>
          <div className="buyers-feedback">
            <div className="buyers-feedback-bar">
              <GreyBar
                onCardClick={this.handleClick}
                data={data.feedback_section.feedback.positive}
                all={data.feedback_section.feedback.all}
                kindOfRating="positive"
                isSelected={rating === 'positive'}
              />
              <GreyBar
                onCardClick={this.handleClick}
                data={data.feedback_section.feedback.neutral}
                all={data.feedback_section.feedback.all}
                kindOfRating="neutral"
                isSelected={rating === 'neutral'}
              />
              <GreyBar
                onCardClick={this.handleClick}
                data={data.feedback_section.feedback.negative}
                all={data.feedback_section.feedback.all}
                kindOfRating="negative"
                isSelected={rating === 'negative'}
              />
            </div>
          </div>
        </div>
        {error && (
          <Message className="error-message" color="red">
            {errorMessage(siteId)}
          </Message>
        )}
        {!error && feedbacks.length > 0 && (
          <div className="rating__wrapper">
            {showSpinner && (
              <ProgressIndicatorCircular
                id="feedback-information-spinner"
                size={isMobile ? 'medium' : 'large'}
                modifier="block"
              />
            )}
            <ul className="rating__list">
              {newReputation && feedbacks.map((value, index) => (
                <RatingListItemNewReputation
                  key={`new-reputation-feedback-item-${index + 1}`}
                  rating={value}
                  newReputation={newReputation}
                />
              ))}
              {!newReputation && feedbacks.map((value, index) => (
                <RatingListItemOldReputation
                  key={`old-reputation-feedback-item-${index + 1}`}
                  rating={value}
                  newReputation={newReputation}
                />
              ))}
            </ul>
          </div>
        )}
        {!error && feedbacks.length === 0 && (
          <p className="buyers-feedback__message">
            {data.feedback_section.feedback.additional_information}
          </p>
        )}
        {!error && subtitle && (
          <SeeMoreFeedbacksLink
            onCardClick={this.handleClick}
            subtitle={subtitle}
            kindOfRating="none"
          />
        )}
      </section>
    );
  }
}

module.exports = FeedbackInformation;
