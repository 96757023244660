const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('../Icon');
const { kindOfRating } = require('../../../../../../utils');

const RatingListItemNewReputation = ({ rating, newReputation }) => (
  <li className="rating__list-item">
    <Icon kind={kindOfRating(rating.rating_name)} reputation={newReputation ? 'new-reputation' : 'old-reputation'} />
    <span className="rating__qualification">
      {rating.rating_name}
    </span>
    { rating.message !== ''
      ? <p>{rating.message}</p>
      : <p />}
    <span className="rating__author">{rating.user.nickname} - {rating.date}</span>
  </li>
);

RatingListItemNewReputation.propTypes = {
  rating: PropTypes.shape({
    rating_name: PropTypes.string,
    message: PropTypes.string,
    date: PropTypes.string,
    user: PropTypes.shape({
      nickname: PropTypes.string.isRequired,
    }),
  }),
  newReputation: PropTypes.bool.isRequired,
};

module.exports = RatingListItemNewReputation;
