const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('../Icon');
const { kindOfRating } = require('../../../../../../utils');

const RatingListItemOldReputation = ({ rating, newReputation }) => (
  <li className="rating__list-item">
    <Icon kind={kindOfRating(rating.rating_name)} reputation={newReputation ? 'new-reputation' : 'old-reputation'} />
    <span className="rating__qualification--old-reputation">
      {rating.user.nickname} ({rating.user.points}) - {rating.date}
    </span>
    { rating.message !== ''
      ? <p>{rating.message}</p>
      : <p />}
    { rating.item
      && <span className="rating__item">
        <span className="rating__item-description">{rating.item.title}</span>
        {rating.item.currency_symbol}{rating.item.price}
      </span>}
  </li>
);

RatingListItemOldReputation.propTypes = {
  rating: PropTypes.shape({
    rating_name: PropTypes.string,
    message: PropTypes.string,
    date: PropTypes.string,
    user: PropTypes.shape({
      nickname: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
    }),
    item: PropTypes.shape({
      currency_symbol: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
  }),
  newReputation: PropTypes.bool.isRequired,
};

module.exports = RatingListItemOldReputation;
