const React = require('react');
// const PropTypes = require('prop-types');
const classNames = require('classnames');

const GetWidth = (current, total) => {
  const number = (current / total) * 100;
  let width = 0;
  if (number !== 0) {
    if (number <= 6) {
      width = '6.00';
    } else {
      width = number;
    }
  }
  return `${width}%`;
};

const GreyBar = ({ onCardClick, data, all, kindOfRating, isSelected }) => {
  const onClick = (e) => {
    if (window.meli_ga) {
      window.meli_ga('send', 'event', {
        eventCategory: 'FEEDBACK',
        eventAction: `SEE_TYPES_OF_FEEDBACK_${(data.rating_name).toUpperCase()}`,
        eventLabel: 'FEEDBACK',
      });
    }
    e.preventDefault();
    onCardClick(kindOfRating, data.value);
  };

  const classes = classNames(`buyers-feedback-bar__items buyers-feedback-bar--${kindOfRating}`,
    (data.value === 0) ? 'disabled' : '', isSelected ? 'is-selected' : '');

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className={classes} href="#" onClick={onClick} data-testid={`feedback_good_${data.rating_name}`}>
      <span className="buyers-feedback-bar__list-item">
        <span className="grey-bar">
          <span className="buyers-feedback-bar__light-blue" style={{ width: GetWidth(data.value, all.value) }} />
        </span>
        <span id="feedback_good" className="buyers-feedback-qualification">
          {data.rating_name} ({data.value})
        </span>
      </span>
    </a>
  );
};

GreyBar.propTypes = {

};

module.exports = GreyBar;
