const React = require('react');
const reactStringReplace = require('react-string-replace');
const { mobileNavigation } = require('../../../../utils/index');

const replaceString = (originalString, matchString, newString) => (
  reactStringReplace(originalString, matchString, () => (newString))
);

const Links = ({
  isWebView,
  linkClass,
  phrase,
  stringClass,
  trackName,
  userId,
}) => {
  let result = phrase.text ? phrase.text : phrase;
  let newString = '';
  let phraseValueIndex = 0;

  const trackOnclick = (url) => {
    if (window.meli_ga && trackName) {
      window.meli_ga('send', 'event', {
        eventCategory: 'LINK_PROFILE',
        eventAction: `OPEN_LINK_${trackName.toUpperCase()}`,
        eventLabel: `LINK_${trackName.toUpperCase()}`,
      });
    }

    if (isWebView) {
      mobileNavigation({ url, userId });
    } else {
      console.log('No es webView, navega normal--->', url);
      window.location.href = url;
    }
  };

  const responseTagSpan = ({ length, index, elem, isBold }) => {
    result = replaceString(result, `<${elem.key}>`, `<span${newString}>${elem.value}`);
    result = replaceString(result, `</${elem.key}>`, '</span>');
    if (!isBold) {
      result = result.join('');
    }
    if (length === index) {
      return <span dangerouslySetInnerHTML={{ __html: result }} />;
    }
    return '';
  };

  const responseTagA = ({ length, index, elem }) => {
    const [initialString, tagA] = result.split(`<${elem.key}>`);
    if (!tagA) return result;
    const [cleanTagA, finalString] = tagA.split(`</${elem.key}>`);
    const indice = newString.indexOf('__');
    const newClass = indice !== -1 ? `${newString.substring(0, indice + 2)}span` : '';

    if (length === index) {
      return (
        <>
          <span className={newClass}>
            { initialString }
            <a
              aria-hidden="true"
              key={elem.value}
              className={newString}
              onClick={() => trackOnclick(elem.value)}
            > {cleanTagA}
            </a>
            { finalString }
          </span>
        </>
      );
    }
    return '';
  };

  const buildResponse = ({ length, index, elem, isUrl = false, isBold = false }) => {
    if (elem !== undefined) {
      if (!isUrl) {
        return responseTagSpan({ length, index, elem, isBold });
      }
      return responseTagA({ length, index, elem });
    }
    return '';
  };

  const evalType = (item) => {
    switch (item.type.toUpperCase()) {
      case 'LINK':
        newString = (linkClass !== undefined && linkClass[phraseValueIndex] !== undefined
          ? linkClass[phraseValueIndex]
          : '');
        phraseValueIndex += 1;
        return buildResponse({ length: phrase.values.length, index: phraseValueIndex, elem: item, isUrl: true });
      case 'STRING':
        newString = (stringClass !== undefined && stringClass[phraseValueIndex] !== undefined
          ? ` class="${stringClass[phraseValueIndex]}"`
          : '');
        phraseValueIndex += 1;
        return buildResponse({ length: phrase.values.length, index: phraseValueIndex, elem: item });
      case 'BOLD-STRING':
        newString = ' class="bold"';
        return buildResponse({ length: phrase.values.length, index: 1, elem: item, isBold: true });
      default: return '';
    }
  };

  return (
    <>
      {
        phrase.values && phrase.values.map((item) => evalType(item))
      }
    </>
  );
};

module.exports = Links;
