/* eslint-disable no-restricted-syntax */
/**
 * Module dependencies
 */
const React = require('react');
const Head = require('nordic/head');
const { MelidataTrack } = require('nordic/melidata/melidata-track');
const Script = require('nordic/script');
const PropTypes = require('prop-types');
const Page = require('nordic/page');
const reactStringReplace = require('react-string-replace');
const Image = require('nordic/image');
const Hotjar = require('nordic/hotjar');
const FeedbackInformation = require('./components/FeedbackInformation');
const StoreInfo = require('./components/StoreInfo');
const BrandInfo = require('./components/BrandInfo');
const LevelInfo = require('./components/LevelInfo');
const Location = require('./components/Location');
const Experience = require('./components/Experience');
const Spacing = require('./components/Spacing');
const Metrics = require('./components/Metrics');
const Carousel = require('./components/Carousel');
const LinksInfo = require('./components/LinksInfo');
const AdditionalInformation = require('./components/AdditionalInformation');
const Links = require('./components/Links');
const utils = require('../../utils/index');
const { isTest } = require('../../utils/constants');

const replaceString = (originalString, matchString, newString) => (
  reactStringReplace(originalString, matchString, () => (newString))
);

const Sentence = (phrase) => {
  let result = phrase.text;
  if (phrase.values !== undefined) {
    for (const v of phrase.values) {
      let value = '';
      switch (v.type.toUpperCase()) {
        case 'STRING':
          if (v.value != null) {
            if (v.value.text) {
              value = Sentence(v.value);
            } else {
              value = v.value;
            }
          }

          result = replaceString(result, `<${v.key}>`, `${value}`);
          result = replaceString(result, `</${v.key}>`, '');
          break;
        default: break;
      }
    }
    result = result.join('');
  }
  return result;
};

const View = (props) => {
  const {
    brandID,
    company,
    device,
    deviceType,
    hiddenThermometer,
    info,
    isMobile,
    isOfficialStore,
    isWebView,
    locale,
    lowEnd,
    scope,
    site,
    siteId,
    supportsWebp,
    translations,
    userIdentifier,
    userRole,
  } = props;

  const isBrand = isOfficialStore === '';

  const newProps = {
    brandID,
    company,
    device,
    deviceType,
    hiddenThermometer,
    info,
    isMobile,
    isOfficialStore,
    isWebView,
    locale,
    lowEnd,
    scope,
    site,
    siteId,
    supportsWebp,
    translations,
    userIdentifier,
    userRole,
  };

  return (
    <Page
      name="profile"
      adaptiveFor="styles"
      deviceType={deviceType}
      state={newProps}
      className={isOfficialStore}
    >
      {!isTest && (
        <MelidataTrack path="/seller_reputation/profile" />
      )}
      <Head>
        {info?.generalInformation?.seo_section?.title && (
          <title>{Sentence(info.generalInformation.seo_section.title)}</title>
        )}
        {info?.generalInformation?.seo_section?.subtitle && (
          <meta name="description" content={Sentence(info.generalInformation.seo_section.subtitle)} />
        )}
        <meta name="robots" content="noindex" />
      </Head>
      <Script src="vendor.js" />
      <div>
        <div className="header-profile">
          {info?.generalInformation?.header_section?.brand?.value && userRole === 'seller' && (
            <Image
              className="header-profile__image"
              src={info.generalInformation.header_section.brand.picture_map.background.secure_url}
              alt="header profile"
            />
          )}
        </div>
        <div className="main-wrapper">
          <div className="content-wrapper">
            {info?.generalInformation?.header_section?.brand?.value && userRole === 'seller' ? (
              <BrandInfo
                name={info.generalInformation.header_section.brand.name}
                brandLogo={info.generalInformation.header_section.brand}
                isWebView={isWebView}
                hiddenThermometer={hiddenThermometer}
                userId={info.generalInformation.user_id}
              />
            ) : (
              <StoreInfo
                nickname={info.generalInformation.header_section.nickname}
                headerSection={info.generalInformation.header_section}
                isWebView={isWebView}
                isMobile={isMobile}
                isBrand={isBrand}
                userId={info.generalInformation.user_id}
              />
            )}
            {isMobile && info?.generalInformation?.header_section?.location && (
              <>
                <Experience
                  data={info.generalInformation.header_section}
                  isWebView={isWebView}
                  userId={info.generalInformation.user_id}
                />
                <Location data={info.generalInformation.header_section.location} isMobile={isMobile} />
              </>
            )}
            {info?.generalInformation?.header_section?.thermometer_section && (
              <LevelInfo
                data={info.generalInformation.header_section}
                isWebView={isWebView}
                isMobile={isMobile}
                isBrand={isBrand}
                hiddenThermometer={hiddenThermometer}
              />
            )}
            {!isMobile && info?.generalInformation?.header_section?.location && (
              <Location data={info.generalInformation.header_section.location} />
            )}
            {!isMobile && isBrand && (
              <div>
                <Spacing />
                {info?.generalInformation?.header_section?.points ? (
                  <p className="store-info__subtitle">
                    <Links
                      phrase={info.generalInformation.header_section.points}
                      isWebView={isWebView}
                      userId={info.generalInformation.user_id}
                    />
                  </p>
                ) : (
                  <Experience
                    data={info.generalInformation.header_section}
                    isWebView={isWebView}
                    userId={info.generalInformation.user_id}
                  />
                )}
              </div>
            )}
            {!isMobile && !hiddenThermometer && (
              <LinksInfo
                data={info.generalInformation.header_section}
                userId={info.generalInformation.user_id}
                isWebView={isWebView}
                brandID={brandID}
                siteId={siteId}
              />
            )}
          </div>
          <div className="inner-wrapper">
            {info?.generalInformation?.metrics_section?.metrics && (
              <Metrics
                info={info.generalInformation.metrics_section.metrics}
                isWebView={isWebView}
                userId={info.generalInformation.user_id}
              />
            )}
            {info?.feedbackInformation !== null && (
              <FeedbackInformation
                data={info.feedbackInformation}
                userIdentifier={userIdentifier}
                limit={utils.isSiteNewReputation(siteId) ? 3 : 6}
                offset={0}
                rating="all"
                newReputation={utils.isSiteNewReputation(siteId)}
                showSpinner={false}
                error={false}
                isMobile={isMobile}
                siteId={siteId}
                userRole={userRole}
              />
            )}
            {info?.generalInformation?.publications_section?.items && (
              <Carousel
                data={info.generalInformation.publications_section}
                isMobile={isMobile}
                isWebView={isWebView}
                supportsWebp={supportsWebp}
                userId={info.generalInformation.user_id}
              />
            )}
            {isMobile && !hiddenThermometer && (
              <div>
                <LinksInfo
                  data={info.generalInformation.header_section}
                  userId={info.generalInformation.user_id}
                  isWebView={isWebView}
                  brandID={brandID}
                  siteId={siteId}
                />
              </div>
            )}
            {info?.generalInformation?.additional_information_section && (
              <AdditionalInformation
                data={info.generalInformation.additional_information_section}
                isWebView={isWebView}
                userId={info.generalInformation.user_id}
              />
            )}
          </div>
        </div>
        <Hotjar />
      </div>
    </Page>
  );
};

View.propTypes = {
  brandID: PropTypes.string,
  company: PropTypes.string,
  device: PropTypes.shape({}),
  deviceType: PropTypes.string.isRequired,
  hiddenThermometer: PropTypes.bool.isRequired,
  info: PropTypes.shape({}),
  isMobile: PropTypes.bool.isRequired,
  isOfficialStore: PropTypes.string.isRequired,
  isWebView: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  lowEnd: PropTypes.bool,
  scope: PropTypes.string,
  site: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  supportsWebp: PropTypes.bool,
  translations: PropTypes.shape({}),
  userIdentifier: PropTypes.string.isRequired,
  userRole: PropTypes.string,
};

module.exports = View;
