/* eslint-disable no-unused-vars */
const React = require('react');
const Links = require('../Links');
const { mobileNavigation, siteConfigs } = require('../../../../utils/index');

const LinksInfo = ({ data, userId, isWebView, brandID, siteId }) => {
  const brand = brandID ? `?brandId=${brandID}` : '';
  let nickname = encodeURIComponent(data.nickname);
  nickname = nickname.replace(/%/g, '%25');
  const Href = (seeProfile, basePath) => {
    const profileBuyer = basePath ? `${basePath}/perfil/comprador/${nickname}${brand}` : `/perfil/comprador/${nickname}${brand}`;
    const profileSeller = basePath ? `${basePath}/perfil/vendedor/${nickname}${brand}` : `/perfil/vendedor/${nickname}${brand}`;
    return seeProfile === 'Ver perfil como vendedor' ? profileSeller : profileBuyer;
  };

  const trackOnclick = (relativePath, sites) => {
    const url = Href(relativePath, sites[siteId].url);

    if (isWebView) {
      mobileNavigation({ url, userId });
    } else {
      window.location.href = url;
    }
  };

  const renderLinkProfile = (seeProfile, sites) => {
    if (isWebView) {
      return (
        <a
          aria-hidden="true"
          key={seeProfile}
          className="seller-info__link"
          onClick={() => trackOnclick(seeProfile, sites)}
        >
          {seeProfile}
        </a>
      );
    }
    return (<a className="seller-info__link" href={Href(seeProfile)}>{seeProfile}</a>);
  };

  return (
    <div className="seller-info__links--wrapper">
      { data.e_shop.value && <Links phrase={data.e_shop.text} linkClass={['seller-info__link']} trackName="ESHOP" isWebView={isWebView} userId={userId} />}
      { data.see_profile && renderLinkProfile(data.see_profile, siteConfigs) }
    </div>
  );
};

module.exports = LinksInfo;
