/**
 * Styles
 */
require('../pages/profile/styles.scss');

/**
 * Module dependencies
 */
const React = require('react');
const { hydrateRoot } = require('nordic/hydrate');
const { I18n, I18nProvider } = require('nordic/i18n');
const { AndesProvider } = require('@andes/context');
const View = require('../pages/profile/view');

/**
 * Get server state
 */
const {
  site,
  siteId,
  device,
  deviceType,
  locale,
  lowEnd,
  translations,
  company,
  info,
  userIdentifier,
  isWebView,
  isOfficialStore,
  isMobile,
  userRole,
  brandID,
  supportsWebp,
  hiddenThermometer,
} = window.__PRELOADED_STATE__;

/**
 * i18n
 */
const i18n = new I18n({ translations });

/**
 * Mount ProfileView on client
 */
hydrateRoot(
  document.getElementById('root-app'),
  <AndesProvider device={device} locale={locale}>
    <I18nProvider i18n={i18n}>
      <View
        site={site}
        siteId={siteId}
        deviceType={deviceType}
        lowEnd={lowEnd}
        company={company}
        info={info}
        userIdentifier={userIdentifier}
        isWebView={isWebView}
        isOfficialStore={isOfficialStore}
        isMobile={isMobile}
        userRole={userRole}
        brandID={brandID}
        supportsWebp={supportsWebp}
        hiddenThermometer={hiddenThermometer}
      />
    </I18nProvider>
  </AndesProvider>,
);
