const React = require('react');
const Links = require('../Links');

const AdditionalInformation = ({ data, isWebView, userId }) => (
  <div className="additional-information">
    <Links
      phrase={data?.subtitle}
      linkClass={['additional-information__link']}
      isWebView={isWebView}
      userId={userId}
    />
  </div>
);

module.exports = AdditionalInformation;
