const React = require('react');
const PropTypes = require('prop-types');
const Links = require('../../../Links');

const DataLevel = ({ data, isWebView, userId }) => (
  <div>
    { data.length > 2
      ? (<div className="data-level__wrapper">
        { data.map(info => (
          <p key={info.id} className="data-level__description">
            <Links phrase={info.data} stringClass={['data-level__number']} isWebView={isWebView} userId={userId} />
          </p>
        ))}
      </div>)
      : null}
    <p />
  </div>
);

DataLevel.propTypes = {
  isWebView: PropTypes.bool.isRequired,
};

module.exports = DataLevel;
