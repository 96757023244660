const React = require('react');
// const PropTypes = require('prop-types');
const classNames = require('classnames');
const Links = require('../Links');

const kindOfMetric = (status) => {
  let result;
  if (status.subtitle.values) {
    result = 'bad';
  } else if (status.compliant) {
    result = 'well';
  } else {
    result = 'newbie';
  }
  return result;
};

const Metrics = ({ info, isWebView, userId }) => {
  const classesClaims = classNames(`metric__drawing metric__drawing--${kindOfMetric(info[0].status)}-claim`);
  const classesHT = classNames(`metric__drawing metric__drawing--${kindOfMetric(info[1].status)}-delivery`);

  return (
    <div className="metric__wrapper">
      <div className="metric">
        <div className={classesClaims} />
        <div className="metric__description">
          <h2>{info[0].status.title}</h2>
          {info[0].status.subtitle.values ? (
            <p>
              <Links
                phrase={info[0].status.subtitle}
                isWebView={isWebView}
                userId={userId}
              />
            </p>
          ) : (
            <p>{info[0].status.subtitle}</p>
          )}
        </div>
      </div>
      <div className="metric metric--last">
        <div className={classesHT} />
        <div className="metric__description">
          <h2>{info[1].status.title}</h2>
          {info[1].status.subtitle.values ? (
            <p>
              <Links
                phrase={info[1].status.subtitle}
                isWebView={isWebView}
                userId={userId}
              />
            </p>
          ) : (
            <p>{info[1].status.subtitle}</p>
          )}
        </div>
      </div>
    </div>
  );
};

Metrics.propTypes = {

};

module.exports = Metrics;
