const React = require('react');

const LightGreen = () => (
  <div className="seller-info__thermometer">
    <svg width="293px" height="11px" viewBox="0 0 293 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Mejoras_V10-FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ASSETS-ZEPLIN" transform="translate(-2066.000000, -212.000000)">
          <g id="Group-11-Copy" transform="translate(2066.000000, 212.000000)">
            <g id="Group-6"> <polygon id="Fill-5-Copy-47" fillOpacity="0.15" fill="#FFB657" points="59.297619 1.74404762 115.107143 1.74404762 115.107143 8.7202381 59.297619 8.7202381" />
              <polygon id="Fill-5-Copy-52" fillOpacity="0.1" fill="#FF605A" points="0 1.74404762 55.8095238 1.74404762 55.8095238 8.7202381 0 8.7202381" />
              <polygon id="Fill-5-Copy-53" fillOpacity="0.25" fill="#FFF044" points="118.595238 1.74404762 174.404762 1.74404762 174.404762 8.7202381 118.595238 8.7202381" />
              <polygon id="Fill-5-Copy-56" fill="#AEEF1B" points="177.892857 0 233.702381 0 233.702381 10.4642857 177.892857 10.4642857" />
              <polygon id="Fill-5-Copy-57" fillOpacity="0.1" fill="#39B54A" points="237.190476 1.74404762 293 1.74404762 293 8.7202381 237.190476 8.7202381" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

/**
 * Expose Component
 */
module.exports = LightGreen;
